import * as React from 'react';
import { graphql } from 'gatsby';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { EventsQuery } from '../../types/graphql-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Header1 } from '../components/headers';
import PostList from '../components/postList';

type Props = {
  data: EventsQuery;
};

const Events: React.FC<Props> = ({ data }: Props) => {
  const events = data.allContentfulEvent.edges.map((edge) => edge.node);
  return (
    <Layout pathname="TODO: figure out pathname">
      <SEO title="Blog Posts" />
      <div className="container mx-auto pt-16">
        <Header1
          className="mx-5 md:mx-0 md:mb-16"
          color="black"
          mobileColorsFlipped
        >
          Events
        </Header1>
        <PostList eventList={events} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Events {
    allContentfulEvent(filter: { hidden: { eq: false } }) {
      edges {
        node {
          id
          title
          slug
          photoDesktop: photo {
            gatsbyImageData(layout: CONSTRAINED, height: 400, width: 750)
          }
          photoMobile: photo {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 1.75
              width: 1280
              cropFocus: CENTER
              placeholder: BLURRED
            )
          }
          date(formatString: "MMMM Do, YYYY")
          dateTimestamp: date(formatString: "X")
        }
      }
    }
  }
`;

export default Events;
